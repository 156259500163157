/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 19, 2020 */
/* FIX Snackbar Position */
.snackbar-container.t-75 {
  top: 75px;
}
/* FONT */
@font-face {
  font-family: 'decima';
  src: url('./assets/fonts/decima-bolditalic-webfont.woff2') format('woff2'),
       url('./assets/fonts/decima-bolditalic-webfont.woff') format('woff'),
       url('./assets/fonts/decima-bolditalic-webfont.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;

}

@font-face {
  font-family: 'decima';
  src: url('./assets/fonts/decima-lightitalic-webfont.woff2') format('woff2'),
       url('./assets/fonts/decima-lightitalic-webfont.woff') format('woff'),
       url('./assets/fonts/decima-lightitalic-webfont.ttf') format('truetype');
  font-weight: lighter;
  font-style: italic;

}

@font-face {
  font-family: 'decima';
  src: url('./assets/fonts/decima-italic-webfont.woff2') format('woff2'),
       url('./assets/fonts/decima-italic-webfont.woff') format('woff'),
       url('./assets/fonts/decima-italic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;

}

@font-face {
  font-family: 'decima';
  src: url('./assets/fonts/decima_light-webfont.woff2') format('woff2'),
       url('./assets/fonts/decima_light-webfont.woff') format('woff'),
       url('./assets/fonts/decima_light-webfont.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;

}

@font-face {
  font-family: 'decima';
  src: url('./assets/fonts/decima-webfont.woff2') format('woff2'),
       url('./assets/fonts/decima-webfont.woff') format('woff'),
       url('./assets/fonts/decima-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'decima';
  src: url('./assets/fonts/decima_bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/decima_bold-webfont.woff') format('woff'),
       url('./assets/fonts/decima_bold-webfont.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;

}